<template>
  <div>
    <div class="row mb-3">
      <div class="col-sm-9">
        <h3>Garantía: {{ garantia.calle }}</h3>
      </div>
      <div class="col-sm-3">
        <select name="garantias" id="garantias" class="form-control">
          <option v-for="tmp_garantia in solicitud.garantias_originacion" :key="tmp_garantia.id" :value="tmp_garantia.id" >{{ tmp_garantia.calle }}</option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <ul>
          <li><span class="subtitle">Tipo:</span><span class="content">{{ garantia.tipogarantia_id }}</span></li>
          <li><span class="subtitle">Propia:</span><span class="content">{{ garantia.propia == 1 ? 'Sí' : 'No' }}</span></li>
          <li><span class="subtitle">¿Está libre de gravamen?:</span><span class="content">{{ garantia.existencia_gravamen == 1 ? 'Sí' : 'No' }}</span></li>
          <li><span class="subtitle">Colonia:</span><span class="content">{{ garantia.colonia }}</span></li>
          <li><span class="subtitle">Alcaldía / Municipio:</span><span class="content">{{ garantia.municipio }}</span></li>
          <li><span class="subtitle">CP:</span><span class="content">{{ garantia.cp }}</span></li>
          <li><span class="subtitle">Estado:</span><span class="content">{{ obtener_estado(garantia) }}</span></li>
          <li><span class="subtitle">Ciudad</span><span class="content">{{ garantia.ciudad ? garantia.ciudad : 'N/A' }}</span></li>
        </ul>

        <ul>
          <li><span class="subtitle">Valor dado por Cliente:</span><span class="content">{{ garantia.valuacion_cliente ? '$'+$helper.moneyFormat(garantia.valuacion_cliente) : 'N/A' }}</span></li>
          <li><span class="subtitle">Descripción del Cliente:</span><span class="content" :title="garantia.descripcion_valuacion_cliente ? garantia.descripcion_valuacion_cliente : ''">{{ garantia.descripcion_valuacion_cliente ? garantia.descripcion_valuacion_cliente : 'N/A' }}</span></li>
          <li><span class="subtitle">Valor estimado:</span><span class="content">{{ garantia.valuacion ? '$'+$helper.moneyFormat(garantia.valuacion) : 'N/A' }}</span></li>
          <li><span class="subtitle">Valor por m<sup>2</sup>:</span><span class="content">{{ garantia.valuacion_m2 ? '$'+$helper.moneyFormat(garantia.valuacion_m2) : 'N/A' }}</span></li>
          <li><span class="subtitle">Intervalo bajo m<sup>2</sup>:</span><span class="content">{{ garantia.intervalo_bajo_m2 ? '$'+$helper.moneyFormat(garantia.intervalo_bajo_m2) : 'N/A' }}</span></li>
          <li><span class="subtitle">Intervalo bajo:</span><span class="content">{{ garantia.intervalo_bajo ? '$'+$helper.moneyFormat(garantia.intervalo_bajo) : 'N/A' }}</span></li>
          <li><span class="subtitle">Intervalo alto m<sup>2</sup>:</span><span class="content">{{ garantia.intervalo_alto_m2 ? '$'+$helper.moneyFormat(garantia.intervalo_alto_m2) : 'N/A' }}</span></li>
          <li><span class="subtitle">Intervalo alto:</span><span class="content">{{ garantia.intervalo_alto ? '$'+$helper.moneyFormat(garantia.intervalo_alto) : 'N/A' }}</span></li>
          <li><span class="subtitle">Vista exterior:</span><span class="content">{{ garantia.vista_exterior ? 'Sí' : 'No' }}</span></li>
          <li><span class="subtitle">Roof garden:</span><span class="content">{{ garantia.roof_garden ? 'Sí' : 'No' }}</span></li>
          <li><span class="subtitle">Número nivel:</span><span class="content">{{ garantia.numero_nivel }}</span></li>
          <li><span class="subtitle">Número piso:</span><span class="content">{{ garantia.numero_pisos }}</span></li>
          <li><span class="subtitle">Balcón:</span><span class="content">{{ garantia.balcon ? 'Sí' : 'No' }}</span></li>
        </ul>
      </div>
      <div class="col-sm-6">
        <ul>
          <li><span class="subtitle">Recámaras:</span><span class="content">{{ garantia.recamaras }}</span></li>
          <li><span class="subtitle">Baños:</span><span class="content">{{ garantia.banos }}</span></li>
          <li><span class="subtitle">Medios baños:</span><span class="content">{{ garantia.medios_banos }}</span></li>
          <li><span class="subtitle">Estacionamientos:</span><span class="content">{{ garantia.estacionamientos }}</span></li>
        </ul>

        <ul>
          <li><span class="subtitle">Superficie terreno:</span><span class="content">{{ garantia.superficie_terreno }}</span></li>
          <li><span class="subtitle">Área construída:</span><span class="content">{{ garantia.area_construida }}</span></li>
          <li><span class="subtitle">Edad propiedad:</span><span class="content">{{ garantia.edad }}</span></li>
        </ul>

        <ul>
          <li><span class="subtitle">Latitud, longitud:</span><span class="content">{{ garantia.latitud }} | {{ garantia.longitud }}</span></li>
          <li><span class="subtitle">Tiempo comercialización:</span><span class="content">{{ tiempo_comercializacion(garantia) }}</span></li>
          <li><span class="subtitle">Puntación total:</span><span class="content">{{ puntuacion_total(garantia) }}</span></li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <img :src="streetview">
        <img :src="googlemaps">
      </div>
    </div>
    
    <div v-if="fachada_url" class="row">
      <div class="col-sm-12">
        <img :src="fachada_url">
      </div>
    </div>
  </div>
</template>

<script>
import apiSolicitudes from '@/apps/solicitudes/api/solicitudes';

export default {
  props: {
    solicitud: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      garantia: {
        calle: null,
        tipogarantia_id: null,
        propia: null,
        existencia_gravamen: null,
        colonia: null,
        municipio: null,
        cp: null,
        ciudad: null,
        valuacion_cliente: null,
        descripcion_valuacion_cliente: null,
        valuacion: null,
        valuacion_m2: null,
        intervalo_bajo_m2: null,
        intervalo_bajo: null,
        intervalo_alto_m2: null,
        intervalo_alto: null,
        vista_exterior: null,
        roof_garden: null,
        numero_nivel: null,
        numero_pisos: null,
        balcon: null,
        recamaras: null,
        banos: null,
        medios_banos: null,
        estacionamientos: null,
        superficie_terreno: null,
        area_construida: null,
        edad: null,
        latitud: null,
        longitud: null,
      }
      ,fachada_url: null
    }
  }
  ,mounted() {
    this.garantia = this.solicitud.garantias_originacion[0];
  }
  ,methods: {
    obtener_estado(garantia) {
      if (garantia.entidad && garantia.entidad.nombre)
        return garantia.entidad.nombre;
      else if (garantia.estado)
        return garantia.estado;
      else
        return 'N/A';
    }
    ,tiempo_comercializacion(garantia) {
      let scoring_garantia = null;

      if (this.solicitud.scoring && this.solicitud.scoring.inmueble)
        this.solicitud.scoring.inmueble.forEach(inmueble => {
          if (inmueble.garantia_id == garantia.id)
            scoring_garantia = inmueble;
        });
      
      return scoring_garantia && scoring_garantia.tiempo_estiamdo_venta ? scoring_garantia.tiempo_estiamdo_venta : 'N/A';
    }
    ,puntuacion_total(garantia) {
      let scoring_garantia = null;

      if (this.solicitud.scoring && this.solicitud.scoring.inmueble)
        this.solicitud.scoring.inmueble.forEach(inmueble => {
          if (inmueble.garantia_id == garantia.id)
            scoring_garantia = inmueble;
        });
      
      return scoring_garantia && scoring_garantia.puntuacion_total ? scoring_garantia.puntuacion_total : 'N/A';
    }
    ,async descargar_fachada() {
      try {
        let res = (await apiSolicitudes.obtener_fachada(this.solicitud.id, this.garantia.fachada_id));
        this.$log.info('fachada descargada', res);

        this.fachada_url = window.URL.createObjectURL(new Blob([res.data], {type: res.headers['content-type']}));
      }catch(e) {
        this.$log.info('error',e);
        // this.$helper.showAxiosError(e,'Error');
      }
    }
  }
  ,computed: {
    streetview() {
      return 'https://maps.googleapis.com/maps/api/streetview?size=500x400&location='+this.garantia.latitud+','+this.garantia.longitud+'&heading=+'+this.garantia.heading+'&pitch='+this.garantia.pitch+'&key=AIzaSyATe84aehuIKi6vzgFsy4skCK_teNHx2o4';
    }
    ,googlemaps() {
      return 'https://maps.googleapis.com/maps/api/staticmap?size=500x400&markers=+'+this.garantia.latitud+','+this.garantia.longitud+'&key=AIzaSyATe84aehuIKi6vzgFsy4skCK_teNHx2o4&scale=1';
    }
    ,fachada() {
      // <img src="{{ url('/operacion/opinion/showdoc/'.$garantia->fachada_id) }}" style='background-size: cover;max-width: 500px;' />
    }
  }
  ,watch: {
    garantia: {
      handler() {
        if (this.garantia && this.garantia.fachada_id)
          this.descargar_fachada();
      }
      ,deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    margin-bottom: 10px;

    li {
      background-color: rgb(235, 233, 233);
      padding: 0px;
      margin: 0px;
      border-bottom: solid 1px #fff;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      span.subtitle {
        background-color: rgb(131, 131, 131);
        width: 220px;
        color: #fff;
        padding: 5px 10px;
        display: inline-block;
      }
    }

    li:last-child {
      border: 0px;
    }
  }
</style>