import Vue from 'vue'
import store from '@/store'
import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_CREDITOS);

export default {
    obtener_solicitudes(pag, buscar, estatus_filtro) {
        let url = `/alianzas/solicitudes?display=frontend&page=${pag}`;

        if (buscar)
            url += '&search='+encodeURIComponent(buscar);

        if(estatus_filtro)
            url += '&f[0][column]=status&f[0][operator]=equal_to&f[0][query_1]='+encodeURIComponent(estatus_filtro);

        return http.get(url)
    }

    ,obtener_solicitud(solicitud) {
        return http.get(`/alianzas/solicitudes/${solicitud}`)
    }

    ,obtener_documento(solicitud,documento) {
        return http.get(`/alianzas/solicitudes/${solicitud}/documento/${documento}`, {responseType: 'blob'});
    }

    ,obtener_fachada(solicitud, fachada) {
        return http.get(`/alianzas/solicitudes/${solicitud}/fachada/${fachada}`, {responseType: 'blob'});
    }

    ,crear_propiedad(solicitud, payload) {
        return http.post(`/alianzas/solicitudes/${solicitud}/propiedades`, payload)
    }

    ,agregar_observacion(solicitud, payload) {
      return http.post(`/alianzas/solicitudes/${solicitud}/observaciones`, payload);
    }
  }